import React from "react"
import StyledWorkProcess from "./style/work-process";
import img1 from "../../assets/images/our-services/planning.png"
import img2 from "../../assets/images/our-services/research.png"
import img3 from "../../assets/images/our-services/dealing.png"
import img4 from "../../assets/images/our-services/consultancy.png"
import bgShape from "../../assets/images/our-services/process-shape.png"

const WorkProcess = () => {
    return (
        <StyledWorkProcess>
            <div className="main-container">
                <div className="head-wrapper">
                    <h2>create a roadmap to achieve desired goals and objectives</h2>
                </div>
                <img className="bg-shape" src={bgShape} alt="" />
                <ul className="process-wrapper">
                    <li>
                        <img src={img1} />
                        <h3>Planning</h3>
                        <p>create a roadmap to achieve desired goals and objectives</p>
                    </li>
                    <li>
                        <img src={img2} />
                        <h3>Research</h3>
                        <p>Gathering information and data to gain knowledge</p>
                    </li>
                    <li>
                        <img src={img3} />
                        <h3>Dealing</h3>
                        <p>Execute the plan skillfully and effectively</p>
                    </li>
                    <li>
                        <img src={img4} />
                        <h3>Consultancy</h3>
                        <p>Providing expert advice and Guidance to succeed</p>
                    </li>
                </ul>
            </div>
        </StyledWorkProcess>
    )
};

export default WorkProcess
