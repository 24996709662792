import tw, { styled } from 'twin.macro';

const StyledWorkProcess = styled.section`
${tw`md:py-24 py-12 bg-primary-dark bg-opacity-5`};
.main-container {
    ${tw`mx-auto max-w-7xl grid px-5 relative`}
    .head-wrapper {
        ${tw`flex flex-col gap-2 items-center`}

        h2 {
            ${tw`md:text-4xl text-3xl font-bold text-center`}
        }

        p {
            ${tw`font-medium`}
        }
    }
    .bg-shape {
        ${tw`absolute top-28 left-20 hidden md:block`}
    }
    .process-wrapper {
        ${tw`flex md:flex-row flex-col justify-between pt-8 gap-12`}
        li {
            ${tw`z-[1] flex flex-col gap-1 md:(w-52 px-0) w-full px-4`}
            &:nth-child(2), &:nth-child(4) {
                ${tw`md:pt-52 pt-0`}
            }

            h3 {
                ${tw`md:text-xl text-lg font-bold text-center pt-3`}
            }

            p {
                ${tw`font-medium text-muted md:w-44 w-full mx-auto text-center`}
            }
        }
    }
}
`;
export default StyledWorkProcess