import tw, { styled } from 'twin.macro';

const StyledAbout = styled.section`
${tw`md:py-24 py-12`};
.main-container {
    ${tw`mx-auto container grid px-5`}
    .head-wrapper {
        ${tw`flex flex-col gap-2 items-center`}

        h2 {
            ${tw`md:text-4xl text-3xl font-bold text-center`}

            span {
                ${tw`text-secondary`}
            }
        }

        p {
            ${tw`font-semibold`}
        }
    }

    .main-content {
        ${tw`flex flex-col gap-3 pt-6`}
        p {
            ${tw`md:text-lg text-base font-medium text-muted leading-normal`}
        }
    }
}
`;
export default StyledAbout