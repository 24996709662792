import React from "react"
import StyledHotelConsultancy from "./style/hotel-consultancy"
import img from "../../assets/images/our-services/hotel-consultancy.jpg"
import { FaArrowRight } from "react-icons/fa"

const HotelConsultancy = () => {
    return (
        <StyledHotelConsultancy>
            <div className="main-container">
                <div className="left">
                    <img src={img} className="image" alt="" />
                </div>
                <div className="right">
                    <h3>Hotel Consultancy</h3>
                    <p>
                        Housefull EHMS is a comprehensive hotel management company, providing hotel owners with management expertise and effective operational solutions. We flexibly view each hotel as a unique asset with a unique selling proposition, enabling the hotel to express its own personality and character and realize its true potential. Our partner hotels experience continued growth thanks to our team of subject matter experts and extensive management skills from pre-opening through full operations.
                    </p>
                    <p>
                        Our comprehensive management solutions cover a wide range of focus areas: operations consulting, pre-operations consulting, auditing, recruitment and training, management information systems, extranet management, operations monitoring, digital marketing and website development, and more.
                    </p>
                    <a className="cta" href="/our-service/hotel-consultancy">
                        Find All Services
                        <FaArrowRight />
                    </a>
                </div>
            </div>
        </StyledHotelConsultancy>
    )
};

export default HotelConsultancy
