import tw, { styled } from 'twin.macro';

const StyledHotelServices = styled.section`
${tw`md:py-24 py-12 bg-secondary bg-opacity-5`};
.main-container {
    ${tw`flex md:flex-row flex-col items-center justify-center container mx-auto md:(px-8 gap-12) gap-4 px-5`}
    .right {
        ${tw`md:w-1/2 w-full flex items-center justify-center`}
        .image {
            ${tw`md:(rounded-2xl rounded-tr-[8rem]) rounded-tr-[4rem] rounded-lg shadow-xl`}
        }
    }
    .left {
        ${tw`md:w-1/2 w-full flex flex-col gap-4 md:p-12 p-0`}
        h3 {
            ${tw`md:text-3xl text-xl font-extrabold`}
        }
        p {
            ${tw`md:text-lg text-base font-medium text-muted pb-4 leading-normal`}
        }
    }

    .cta {
        ${tw`flex flex-row gap-3 font-semibold text-lg text-secondary items-center`}
    }
}
`;
export default StyledHotelServices