import React from "react"
import Layout from "../components/layout"
import Banner from "../components/common/Banner"
import bannerImg from "../assets/images/our-services-banner.jpg"
import AboutService from "../components/our-services/AboutService";
import WorkProcess from "../components/our-services/WorkProcess";
import HotelConsultancy from "../components/our-services/HotelConsultancy";
import HotelServices from "../components/our-services/HotelServices";

const OurServices = () => {
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Our Services', link: '' },
  ]
  return (
    <Layout>
      <Banner
        data="Our Services"
        image={bannerImg}
        crumbs={crumbs}
      />
      <AboutService />
      <WorkProcess />
      <HotelConsultancy />
      <HotelServices />
    </Layout>
  )
};

export default OurServices
