import React from "react"
import img from "../../assets/images/our-services/hotel-service.jpg"
import { FaArrowRight } from "react-icons/fa"
import StyledHotelServices from "./style/hotel-services"

const HotelServices = () => {
    return (
        <StyledHotelServices>
            <div className="main-container">
                <div className="left">
                    <h3>Hotel Service</h3>
                    <p>
                        Housefull EHMS provides hotel development and operations consulting services to hotel owners looking to improve financial performance, increase guest satisfaction and undeniably positive team morale.
                    </p>
                    <p>
                        Housefull EHMS has extensive experience in successfully assisting hotel development companies and developers in concept and physical development, construction, staffing and new hotel openings. These services are often accompanied by an ongoing management relationship. In this way, we can ensure that new hotel projects are designed and built for maximum return on investment and that the staff ensure a successful, organized opening.
                    </p>
                    <a className="cta" href="/our-service/hotel-service">
                        Find All Services
                        <FaArrowRight />
                    </a>
                </div>
                <div className="right">
                    <img src={img} className="image" alt="" />
                </div>
            </div>
        </StyledHotelServices>
    )
};

export default HotelServices