import React from "react"
import StyledAbout from "./style/about";

const AboutService = () => {
    return (
        <StyledAbout>
            <div className="main-container">
                <div className="head-wrapper">
                    <h2>About <span> Our Services</span></h2>
                    <p>We’re Providing Best Solutions For Your Hotel Business</p>
                </div>
                <div className="main-content">
                    <p>
                        Housefull Hotel Management Service is a market leader in hotel consulting, offering a wide range of specialist solutions, all designed to enhance clients' financial returns and asset value. Our range of services ranges from planning, design and implementation through to sales and marketing support. From independent restaurants to five-star restaurants, our services cover all areas of the hotel and catering industry.
                    </p>
                    <p>
                        We have a comprehensive understanding of the hotel business. Our solutions are designed to help clients achieve the best financial return.
                    </p>
                    <p>
                        Drawing on our extensive in-house industry database, strong and deep professional network and over 20 years of consulting experience, we have comprehensive market intelligence for the hotel industry.
                    </p>
                </div>
            </div>
        </StyledAbout>
    )
};

export default AboutService